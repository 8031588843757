import ReactDOM from 'react-dom/client'
import { staticConfig } from './providers/ConfigProvider'

declare global {
  interface Window {
    releaseId?: string
  }
}

const POLL_INTERVAL = 300000 // FIVE MINUTES
// const POLL_INTERVAL = 3000

let timer

const Message = () => (
  <button
    className="fixed top-20 right-8 w-full max-w-96 p-3 text-left border border-gray-300 rounded-lg text-gray-700 bg-white z-50 shadow-lg"
    type="button"
    onClick={() => document.location.reload()}
  >
    <span className="block font-medium text-base">A new version of Gearflow is ready!</span>
    <span className="block text-sm">Click here to get it.</span>
  </button>
)

const renderUpdateMessage = () => {
  const el = document.createElement('div')

  document.body.appendChild(el)
  ReactDOM.createRoot(el).render(<Message />)
}

const checkRelease = async () => {
  const release = await fetch(`${staticConfig.gfBaseUrl}/release`)
  const latestReleaseId = await release.json()

  // if (true) {
  if (window.releaseId !== latestReleaseId) {
    renderUpdateMessage()
    clearInterval(timer)
  }
}

const pollForUpdates = () => {
  timer = setInterval(checkRelease, POLL_INTERVAL)
}

export default { pollForUpdates }
